.hero-video-container {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  height: 650px;
  background-color: black;
}

.hero-video {
  height: 100%;
  background-color: rgb(0, 0, 0);
  opacity: 0.4;
}

.hero-video-container video {
  object-fit: cover;
}

.hero-text {
  position: absolute;
  color: rgb(0, 0, 0);
  top: 10em;
  text-align: center;
  align-self: center;
}

.hero-text h1 {
  font-size: 3.5em;
  align-self: center;
}
.hero-text h2 {
  margin-top: 40px;
  font-size: 1.2em;
  opacity: 0.8;
}

.shop-now-btn {
  opacity: 0.9;
  font-weight: bolder;
  position: absolute;
  bottom: 10rem;
  align-self: center;
  font-size: 2rem;
  font-weight: 900;
  padding: 1.1rem 3.5rem;
  border: none;
  justify-self: stretch;
  border-radius: 40px;
  color: rgb(0, 0, 0);
  background-color: rgb(217, 217, 217);
}

.shop-now-btn:hover {
  opacity: 1;
  cursor: pointer;
  background-position: left center; /* change the direction of the change here */
  color: rgb(0, 0, 0);
  text-decoration: none;
  background-color: rgb(255, 255, 255);
}

@media (min-width: 768px) {
  .hero-video-container {
    height: 810px;
  }
  .hero-text h1 {
    font-size: 6em;
    align-self: center;
  }
  .hero-text h2 {
    margin-top: 40px;
    font-size: 1.5em;
    opacity: 0.8;
  }
}
