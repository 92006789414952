.billing-container {
  border-top: 1px solid rgb(226, 226, 226);
  padding: 15px;
  margin: 0 15px;
}

.billing-container h1 {
  text-align: center;
}

.price-details-container > * {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  color: grey
}

.price-details-container > *:first-child {
  margin-top: 10px;
}

.price-details-container > *:last-child {
  margin-top :10px;
  font-weight: 700;
  font-size: 35px;
  color: black
}
