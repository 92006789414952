.product-details-image img {
  width: 90vw;
  object-fit: cover;
  height: 65vw;
  margin-bottom: 50px;
  margin-top: 30px;
}

@media (min-width: 768px) {
  .product-details-image img {
    width: 50vw;
    object-fit: cover;
    height: 500px;
    margin-top: -70px;
  }
}
