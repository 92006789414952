.signup-container {
  padding: 2em;
  width: 350px;
  margin: auto;
  margin-top: 10vh;
  display: flex;
  flex-flow: column nowrap;
  height: 630px;
  border-radius: 10px;
  box-shadow: 0px 2px 33px 3px rgb(234, 234, 234);
}

.signup-body {
  flex-grow: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
}

.signup-container h2 {
  text-align: center;
  margin-bottom: 10px;
}

.signup-container .email-container {
  display: flex;
  flex-flow: column nowrap;
}

.signup-container .email-container > label {
  margin-bottom: 5px;
}

.signup-container .email-container input {
  padding: 5px 10px;
  border-radius: 3px;
  border: 1px solid rgb(210, 210, 210);
}

.signup-container .input-container {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.signup-container .password-container {
  display: flex;
  flex-flow: column nowrap;
}

.signup-container .password-container > label {
  margin-bottom: 5px;
}

.signup-container .password-container .input-container {
  padding: 5px 10px;
  border-radius: 3px;
  border: 1px solid rgb(210, 210, 210);
}

.signup-container .confirm-password-container .input-container {
  margin-top: 5px;
  padding: 5px 10px;
  border-radius: 3px;
  border: 1px solid rgb(210, 210, 210);
}

.signup-container .hide-show-password-eye {
  margin-right: 3px;
  color: rgb(127, 127, 127);
  cursor: pointer;
}

.password-container {
  display: flex;
  flex-flow: column nowrap;
}

.signup-btn-container {
  display: flex;
  flex-flow: column nowrap;
}

.signup-container .signup-btn-container button {
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;

  border: none;

  color: var(--primary-color);
}

.signup-container .signup-btn-container button:hover {
  background-color: var(--secondary-background-color-onhover);
  cursor: pointer;
}

.signup-container .signup-btn-container input {
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;

  border: none;

  border: none;
  margin-bottom: 10px;
  color: white;

  background-color: var(--primary-color);
}

.signup-container .signup-btn-container input:hover {
  cursor: pointer;
  background-color: black;
}

.remember-me-container {
  display: flex;
  justify-content: space-between;
}
.signup-container .error {
  color: red;
  font-size: 0.8rem;
  padding: 0;
}

.signup-body > a {
  margin: 0 auto;

  font-size: 0.8rem;
  color: rgb(144, 144, 144);
  text-decoration: none;
}

.input-container {
  border: 1px solid black;
  display: flex;
  justify-content: space-between;
}

.input-container input {
  border: none;
  flex-grow: 1;
}

.input-container input:focus {
  outline: none;
}

.name-container {
  display: flex;
  flex-flow: column nowrap;
}

.signup-container .name-container > label {
  margin-bottom: 5px;
}

.signup-container .name-container input {
  padding: 5px 10px;
  border-radius: 3px;
  border: 1px solid rgb(210, 210, 210);
}

@media (min-width: 768px) {
  .signup-container {
    width: 400px;
  }
}
